import { Component, OnInit } from '@angular/core';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-policy-advocacy',
  templateUrl: './policy-advocacy.component.html',
  styleUrls: ['./policy-advocacy.component.css']
})
export class PolicyAdvocacyComponent implements OnInit {

  pageData: any;
  loading = true;
  loadingActivities = true;
  loadingPositionPapers = true;

  activityReports;
  positionPapers;

  error;
  errorMessage;

  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {

    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 24;
    this.setLoading(true, 2, {name: 'activities'});
    this.setLoading(true, 2, {name: 'positionpapers'});

    // ---------- Page configuration ----------
    this.backend.getPages(config)
    .then(homepage => {
      this.pageData = homepage;
      console.log('Page data: ', this.pageData);
      this.setLoading(false);
    }).catch((error) => this.setError(error));

    // ---------- Activities ----------
    this.backend.getActivityReports()
    .then(activityReports => {
      console.log('Activity reports: ', activityReports);
      this.activityReports = activityReports;
      this.setLoading(false, 2, {name: 'activities'});
      this.loadingActivities = false;
    }).catch((error) => this.setError(error));

    // ---------- Position papers ----------
    this.backend.getPositionpapers()
    .then(positionPapers => {
      console.log('Position papers:', positionPapers);
      this.positionPapers = positionPapers;
      this.loadingPositionPapers = false;
      this.setLoading(false, 2, {name: 'positionpapers'});
    }).catch((error) => this.setError(error));
  }


  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.loading = false;
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean, level: number = 1, obj: any = null) {
    this.sync.updateLoading(value, level, obj);
    this.loading = value;
  }

}
