import { Component, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  pageData: any;
  id = null;
  loading = true;
  error = false;
  errorMessage;
  people;

  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 20;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
    .then(people => {
      this.pageData = people;
      console.log('Page data: ', this.pageData);
      this.people = people.acf;
      console.log('People: ', this.people);
      this.setLoading(false);
      
    }).catch((error) => this.setError(error));
  }

  read_bio(event){
    //console.log(event);
    event.target.nextSibling.style.visibility="visible";
    event.target.nextSibling.style.opacity=1;
    event.target.nextSibling.style.pointerEvents= "all";
  };
  close_bio(event){
    //console.log(event.target);
    if(event.target.parentNode.classList.contains("secretary-bio")){
      event.target.parentNode.style.visibility="hidden";
      event.target.parentNode.style.opacity=0;
  }

  };

  private loadingComplete(newData) {
    this.setLoading(false);
    if (environment.VERBOSE) {
      console.log('New data:', newData);
    }
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
