import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {


  pageData: any;
  loading = true;
  error = false;
  errorMessage;
  activities;


  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 335;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
    .then(pageData => {
      console.log('Page data: ', pageData);
      this.pageData = pageData;
      this.setLoading(false);
    });

    // ---------- Events configuration ----------
    this.backend.getCalendar()
      .then(activities => {
        this.activities = activities;
        console.log('Activities: ', this.activities);
      }).catch((error) => this.setError(error));
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
