import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {

  pageData: any;
  organisation;
  loading = true;
  error = false;
  errorMessage;


  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 18;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
      .then(organisation => {
        this.pageData = organisation;
        console.log('Page data: ', this.pageData);
        this.organisation = organisation.acf;
        console.log('organisation: ', this.organisation);
        this.setLoading(false);
      }).catch((error) => this.setError(error));
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }
}
