import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { APIHelperService } from './apihelper.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient,
              private APIHelper: APIHelperService) { }


  /*
    ---------- CONFIG OBJECT ----------
    id: number
    params: {
      page
      per_page
      search
      after
      before (date)
      order (desc: default || asc)
      orderby (date: default || author, date, id, include, modified, parent, relevance, slug, include_slugs, title)
      slug
      status
      categories
      tags
      tags_exclude
      start_date
      end_date
    }
    */


  public getPages(config = null) {
    return this.getWrapper('pages', config);
  }

  public getPressReleases(config = null) {
    return this.getWrapper('press_release', config);
  }

  public getActivityReports(config = null) {
    return this.getWrapper('activity_report', config);
  }

  public getPositionpapers(config = null) {
    return this.getWrapper('position_paper', config);
  }

  public getCalendar(config = null) {
    return this.getWrapper('calendar', config);
  }

  public getEuProjects(config = null) {
    return this.getWrapper('eu_project', config);
  }

  public getCommunication(config = null) {
    return this.getWrapper('communication', config);
  }

  public getEuMembers(config = null) {
    return this.getWrapper('eu_member', config);
  }

  public getCountries(config = null) {
    return this.getWrapper('country', config);
  }

  public getMaFile(config = null) {
    return this.getWrapper('ma_file', config);
  }

  public getMaFileCategory(config = null) {
    return this.getWrapper('ma_file_category', config);
  }

  private getWrapper(API, config = null) {
    return new Promise<any>((resolve, reject) => {
      const API_URL = this.APIHelper.generateAPILink(API, config);
      this.http.get(API_URL, {observe: 'response'})
      .subscribe(response => {
        resolve(response.body);
      }, error => {
        reject(error.error);
      });
    });


    /*
    [Mignano A.] I was trying to create a loader with the current progress over total number of bytes
    but Wordpress doesn't pass the Content-Lenght header in its REST API (motherfuckers).
    */
    // return new Promise<any>((resolve, reject) => {
    //   const API_URL = this.APIHelper.generateAPILink(API, config);
    //   const req = new HttpRequest('GET', API_URL, {
    //     observe: 'response',
    //     reportProgress: true,
    //   });

    //   this.http.request(req).subscribe((event: HttpEvent<any>) => {
    //     // Via this API, you get access to the raw event stream.
    //     // Look for download progress events.
    //     console.log('Event:', event);
    //     if (event.type === HttpEventType.DownloadProgress) {
    //       // This is an download progress event. Compute and show the % done:
    //       const percentDone = Math.round(100 * event.loaded / event.total);
    //       console.log(`File is ${percentDone}% downloaded.`);
    //     } else if (event instanceof HttpResponse) {
    //       console.log('Completed: ', event);
    //       resolve(event.body);
    //     }
    //   });
    // });
  }


  public makeHTTPRequest(url) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, {observe: 'response'})
      .subscribe(response => {
        resolve(response.body);
      }, error => {
        reject(error.error);
      });
    });
  }

  private getEventMessage(event: HttpEvent<any>, file: File = null) {
    switch (event.type) {
    case HttpEventType.Sent:
      return `Uploading file "${file.name}" of size ${file.size}.`;

    case HttpEventType.UploadProgress:
      // Compute and show the % done:
      const percentDone = Math.round(100 * event.loaded / event.total);
      return `File "${file.name}" is ${percentDone}% uploaded.`;

    case HttpEventType.Response:
      return `File "${file.name}" was completely uploaded!`;

    default:
      return `File "${file.name}" surprising upload event: ${event.type}.`;
      }
    }

  public search(term) {
    return new Promise((resolve, reject) => {
      const API_URL = this.APIHelper.generateSearchLink(term);
      this.http.get(API_URL, {observe: 'response'})
      .subscribe(response => {
        resolve(response.body);
      }, error => {
        reject(error.error);
      });
    });
  }
}
