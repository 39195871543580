export class MaFile {
  id: number;
  date: string;
  slug: string;
  status: string;
  type: string;
  title: string;
  // tslint:disable-next-line: variable-name
  ma_file_category: string[];

  filename: string;
  filesize: number;
  url: string;
  name: string;
  // tslint:disable-next-line: variable-name
  mime_type: string;
  subtype: string;
  icon: string;

  constructor(id, date, slug, status, title, ma_file_category, file) {
    this.id = id;
    this.date = date;
    this.slug = slug;
    this.status = status;
    this.title = title;
    this.ma_file_category = ma_file_category;

    this.id = file.id;
    this.filename = file.filename;
    this.filesize = file.filesize;
    this.url = file.url;
    this.name = file.name;
    this.mime_type = file.mime_type;
    this.type = file.type;
    this.subtype = file.subtype;
    this.icon = file.icon;
  }

  static fromDBData(obj: any) {
    // tslint:disable-next-line: max-line-length
    return new MaFile(obj.id, obj.date, obj.slug, obj.status, obj.title.rendered, obj.ma_file_category, obj.acf.upload_media);
  }
}
