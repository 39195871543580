import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { WhatwedoComponent } from './pages/whatwedo/whatwedo.component';
import { PressReleasesComponent } from './pages/press-releases-container/press-releases/press-releases.component';
import { PressReleaseComponent } from './pages/press-releases-container/press-release/press-release.component';
import { KeepInTouchComponent } from './pages/keep-in-touch/keep-in-touch.component';
import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { PeopleComponent } from './pages/who-we-are/people/people.component';
import { OrganisationComponent } from './pages/who-we-are/organisation/organisation.component';
import { PolicyAdvocacyComponent } from './pages/whatwedo/policy-advocacy/policy-advocacy.component';
import { CampaignsCommunicationComponent } from './pages/whatwedo/campaigns-communication/campaigns-communication.component';
import { ActivitiesComponent } from './pages/activities/activities/activities.component';
import { HeaderComponent } from './pages/header/header.component';
import { SearchInputComponent } from './pages/search/search-input/search-input.component';
import { SearchPageComponent } from './pages/search/search-page/search-page.component';
import { SectionOpeningPageComponent } from './pages/section-opening-page/section-opening-page.component';
import { SectionSocialWallComponent } from './pages/section-social-wall/section-social-wall.component';
import { SliderCcComponent } from './pages/home/slider-cc/slider-cc.component';
import { HomePressReleasesComponent } from './pages/home/home-press-releases/home-press-releases.component';
import { EventComponent } from './pages/calendar-event/event/event.component';
import { EventsComponent } from './pages/calendar-event/events/events.component';
import { CejaIsSocialComponent } from './pages/ceja-is-social/ceja-is-social.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { BottomDetectorDirective } from './directives/bottom-detector.directive';
import { FooterComponent } from './pages/footer/footer.component';
import { UpcomingActivitiesComponent } from './pages/home/upcoming-activities/upcoming-activities.component';
import { SliderPresidencyComponent } from './pages/who-we-are/people/slider-presidency/slider-presidency.component';
import { MembersMapComponent } from './pages/who-we-are/people/members-map/members-map.component';
import { EuProjectsComponent } from './pages/whatwedo/eu-projects/eu-projects.component';
import { ActivityComponent } from './pages/activities/activity/activity.component';
import { NavigationPagesComponent } from './pages/navigation-pages/navigation-pages.component';

import { LoginComponent } from './pages/login/login.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { MembersAreaComponent } from './pages/members-area/members-area.component';
import { CommunicationComponent } from './pages/whatwedo/campaigns-communication/communication/communication.component';
import { SliderCComponent } from './pages/whatwedo/campaigns-communication/communication/slider-c/slider-c.component';
import { PageComponent } from './pages/page/page.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WhatwedoComponent,
    PressReleasesComponent,
    PressReleaseComponent,
    KeepInTouchComponent,
    WhoWeAreComponent,
    PeopleComponent,
    OrganisationComponent,
    PolicyAdvocacyComponent,
    CampaignsCommunicationComponent,
    ActivitiesComponent,
    HeaderComponent,
    SearchInputComponent,
    SearchPageComponent,
    SectionOpeningPageComponent,
    SectionSocialWallComponent,
    SliderCcComponent,
    HomePressReleasesComponent,
    CejaIsSocialComponent,
    BottomDetectorDirective,
    FooterComponent,
    UpcomingActivitiesComponent,
    SliderPresidencyComponent,
    MembersMapComponent,
    EuProjectsComponent,
    ActivityComponent,
    NavigationPagesComponent,
    LoginComponent,
    MembersAreaComponent,
    CommunicationComponent,
    SliderCComponent,
    PageComponent,
    EventComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SwiperModule
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
