import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';

@Component({
  selector: 'app-home-press-releases',
  templateUrl: './home-press-releases.component.html',
  styleUrls: ['./home-press-releases.component.css']
})
export class HomePressReleasesComponent implements OnInit {

  pressReleases;
  loading = true;

  constructor(private backend: BackendService) { }

  ngOnInit() {

    this.loading = true;

    const config: APIConfig = new APIConfig();
    config.params.per_page = 3;
    this.backend.getPressReleases(config)
    .then(prs => {
      this.pressReleases = prs;
      console.log('Latest 3 PR: ', this.pressReleases);
      this.loading = false;
    });
  }

  createExcerpt(longText: string, len: number) {
    // Remove html tags
    let newText = longText.split(' ').slice(0, len).join(' ');
    newText = newText.replace(/<\/?[^>]+(>|$)/g, '');
    newText += ' [...]';
    return newText;
  }

}
