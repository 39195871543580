import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  slug;
  loading = false;
  pageData;
  page;

  constructor(private route: ActivatedRoute,
              private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {

    const config: APIConfig = new APIConfig();

    if (this.route.snapshot.paramMap.has('slug')) {
      this.slug = this.route.snapshot.paramMap.get('slug');
      config.params.slug = this.slug;
    } else {
      this.slug = null;
    }

    this.backend.getPages(config)
    .then(page => {
      this.page = page[0];
      console.log(this.page);
    });

    this.setLoading(false);
  }

  private setLoading(value: boolean, level = 1, obj: any = null) {
    this.sync.updateLoading(value, level, obj);
    this.loading = value;
  }

}
