import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  id;
  pageData: any;
  loading = true;
  error = false;
  errorMessage;
  activity;


  constructor(private route: ActivatedRoute,
              private backend: BackendService,
              private sync: SyncService) {
                    this.pageData = {
                      title: {
                        rendered: 'Activity'
                      },
                      acf: {
                        section_opening: 'https://via.placeholder.com/1920x1080/777'
                      }
                    };
              }

  ngOnInit() {

    const config: APIConfig = new APIConfig();

    if (this.route.snapshot.paramMap.has('id')) {
      this.id = this.route.snapshot.paramMap.get('id');
      config.id = this.id;
    } else {
      this.id = null;
    }

    this.setLoading(true);

    // ---------- Page configuration ----------
    this.backend.getCalendar(config)
      .then(activity => {
        this.activity = activity;
        console.log('Activity: ', this.activity);
        this.setLoading(false);
      }).catch((error) => this.setError(error));
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
