import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { environment } from 'src/environments/environment';
import { TouchSequence } from 'selenium-webdriver';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  pageData: any;
  organisation;
  loading = true;
  error = false;
  errorMessage;

  // username = 'a.musumarra@ceja.eu';
  username = '';
  // password = 'CB9dGkSk3mKM0JkhGkz6lEyn';
  password = '';

  constructor(private backend: BackendService,
              private sync: SyncService,
              private auth: AuthService,
              private router: Router) { }

  ngOnInit() {

    this.pageData = {
      acf: {
        section_opening: ''
      },
      title: {
        rendered: 'Login'
      }
    };

    this.setLoading(false);
  }

  login() {
    console.log(this.username + ' ' + this.password);
    this.auth.login(this.username, this.password)
    .then(r => {
      this.sync.login(true);
      this.router.navigateByUrl('/members-area');
    }).catch(error => {
      this.sync.login(false);
      this.errorMessage = error.message;
    });
  }


  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
