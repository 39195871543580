import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';

@Component({
  selector: 'app-ceja-is-social',
  templateUrl: './ceja-is-social.component.html',
  styleUrls: ['./ceja-is-social.component.css']
})
export class CejaIsSocialComponent implements OnInit {

  cejaIsSocial;
  loading = true;

  constructor(private backend: BackendService) { }

  ngOnInit() {

  }

}
