import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { APIConfig } from 'src/app/shared/APIConfig.model';

@Component({
  selector: 'app-upcoming-activities',
  templateUrl: './upcoming-activities.component.html',
  styleUrls: ['./upcoming-activities.component.scss']
})
export class UpcomingActivitiesComponent implements OnInit {


  upcomingEvents;
  previousEvents;

  loading = true;
  error;
  errorMessage;

  constructor(private backend: BackendService) { }

  ngOnInit() {

    // ---------- Upcoming event ----------
    const config: APIConfig = new APIConfig();
    config.params.start_date = this.generateTodayString();
    config.params.per_page = 4;

    this.backend.getCalendar(config)
    .then((calendar) => {
      this.upcomingEvents = calendar.events;
      console.log('Upcoming event: ', this.upcomingEvents);
      this.loadingComplete(calendar);
    }).catch((error) => this.setError(error));

    // ---------- Previous event ----------
    // const config2: APIConfig = new APIConfig();
    // config2.params.start_date = this.generateTodayString(6); // 6 months before;
    // config2.params.end_date = this.generateTodayString();

    // this.backend.getCalendar(config2)
    // .then((calendar) => {
    //   this.previousEvents = calendar.events;
    //   console.log('Previous: ', this.previousEvents);
    //   this.loadingComplete(calendar);
    // }).catch((error) => this.setError(error));

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  private loadingComplete(newData) {
    this.loading = false;
    if (environment.VERBOSE) {
      // console.log('New data:', newData);
    }
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.loading = false;
    this.error = true;
    this.errorMessage = error;
  }

  private generateTodayString(monthsBefore = null) {
    // Template: 1990-09-12 00:00:00
    const date = new Date();
    if (monthsBefore !== null) {
      date.setMonth( date.getMonth() - monthsBefore);
    }
    let todayString = '';
    todayString += date.getFullYear() + '-';
    todayString += (date.getMonth() + 1) + '-';
    todayString += date.getDate() + ' ';

    todayString += '00:00:00';

    return todayString;
  }

}
