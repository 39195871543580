import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaigns-communication',
  templateUrl: './campaigns-communication.component.html',
  styleUrls: ['./campaigns-communication.component.css']
})
export class CampaignsCommunicationComponent implements OnInit {


  pageData: any;
  communications;
  loading = true;
  error = false;
  errorMessage;


  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 26;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
      .then(organisation => {
        this.pageData = organisation;
        console.log('Page data: ', this.pageData);
      }).catch((error) => this.setError(error));

    // ---------- Communication ----------
    const config2: APIConfig = new APIConfig();
    this.backend.getCommunication(config2)
    .then(communications => {
      this.communications = communications;

      this.communications.forEach(c => {
        if (c._links['wp:featuredmedia'] != null) {
          this.backend.makeHTTPRequest(c._links['wp:featuredmedia'][0].href)
          .then(object => {
            c.featured_media_object = object;

            console.log('Communications: ', communications);
            this.setLoading(false);
          });
        }

      });

    });
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
