import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logged = false;

  constructor(private auth: AuthService) {
  }

  ngOnInit() {

    this.logged = this.auth.isAuthenticated();
    this.auth.isLogged().subscribe(status => this.logged = status);

    window['ngHeader'] = this;
    window['ngHeader'].__intervalInitMobile = window.setInterval(
      function(){
        window['ngHeader'].initMobile();
        if (window['ngHeader'].__initMobile){
          window.clearInterval(window['ngHeader'].__intervalInitMobile);
        }
      },
      1000
    );

  }

  showMenu(){
    this.initMobile();
    var menuMobile = <HTMLElement> document.querySelector('.menuMobile');
    if (menuMobile.style.visibility == 'visible'){
      menuMobile.style.visibility = 'hidden';
      menuMobile.style.opacity = '0';
      menuMobile.style.width = '0';
      menuMobile.style.height = '0';
      window.onscroll = function(){};
    }else{
      window.scrollTo(0, 0);
      menuMobile.style.visibility = 'visible';
      menuMobile.style.opacity = '1';
      menuMobile.style.width = '100vw';
      menuMobile.style.height = '100vh';
      window.onscroll = function(){window.scrollTo(0, 0); };
    }
  }
  public initMobile(){
    if (this.__initMobile)return true;
    console.dir('initMobile');
    var mainMenu = document.querySelectorAll(' #header .item-page');
    var menuMobile = <HTMLElement> document.querySelector('.menuMobile');
    if (!mainMenu[0].querySelector('a').getAttribute('href'))return false;
    try{
      for (var i = 0; i < mainMenu.length; i++){
        var submenuList = mainMenu[i].querySelectorAll('a');

        if (submenuList.length > 0){
          var fld = document.createElement('div');
          fld.setAttribute(menuMobile.attributes[0]['name'], menuMobile.attributes[0]['value']);
          fld.appendChild(submenuList[0].cloneNode(true));
          //fld.querySelector("a").href="javascript:void(0);" ;

          fld.addEventListener('click', function() {
            if (this.classList.contains('active'))
              this.classList.remove('active');
            else this.classList.add('active');
          });


          if (submenuList.length > 1){
            fld.querySelector('a').href = 'javascript:void(0);' ;
            var submenuContainer = document.createElement('div');
            submenuContainer.setAttribute(menuMobile.attributes[0]['name'], menuMobile.attributes[0]['value']);
            submenuContainer.className = 'sub_menu';
            fld.appendChild(submenuContainer);
            for (var j = 1; j < submenuList.length; j++){
              var child = document.createElement('div');
              child.setAttribute(menuMobile.attributes[0]['name'], menuMobile.attributes[0]['value']);

              child.appendChild(submenuList[j].cloneNode(true));
              submenuContainer.appendChild(child);
            }
          }
          menuMobile.appendChild(fld);

        }
      }
      this.__initMobile = true;
    }catch (ex){console.dir(ex); }
  }

  public __initMobile = false;
  public __intervalInitMobile = 0;
}
