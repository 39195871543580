import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend.service';
import { APIConfig } from '../../shared/APIConfig.model';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  homepage: any;
  loading = true;

  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 14;
    this.backend.getPages(config)
    .then(homepage => {
      this.homepage = homepage;
      console.log('Homepage: ', this.homepage);
      this.setLoading(false);
    });

    var navBtnList = document.getElementsByClassName("navBtn");
    for (var i = 0; i < navBtnList.length; i++) {
      navBtnList[i].addEventListener('click', function() {
        var navBtnList = document.getElementsByClassName("navBtn");
        for (var i = 0; i < navBtnList.length; i++) {
          (<HTMLElement>navBtnList[i]).className = (<HTMLElement>navBtnList[i]).className.replace(" active", "");
        }
        this.className+=" active";
        ceyaIsSocialSelectNav(this.getAttribute("social"));
      });
    }

/*
    window.ceyaIsSocialSelectNav=function(nav){
      document.getElementById("navBtnTwitter").className ="btn";
      document.getElementById("navBtnFacebook").className ="btn";
      document.getElementById("navBtnInstagram").className ="btn";
      var panelList = document.getElementsByClassName("cardPanel");
      for (var i = 0; i < panelList.length; i++) {
        panelList[i].style.display="none";
      }
      document.getElementById("navBtn"+nav).className ="btn active";
      document.getElementById("navPanel"+nav).style.display="flex";
    }

    document.getElementById("navBtnTwitter").onmouseup=function(){ceyaIsSocialSelectNav("Twitter"); };
    document.getElementById("navBtnFacebook").onmouseup=function(){ceyaIsSocialSelectNav("Facebook"); };
    document.getElementById("navBtnInstagram").onmouseup=function(){ceyaIsSocialSelectNav("Instagram"); };
    */
  };



  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}

function ceyaIsSocialSelectNav(nav){
  var panelList = document.getElementsByClassName("cardPanel");
  for (var i = 0; i < panelList.length; i++) {
      (<HTMLElement>panelList[i]).style.display="none";
  }
  document.getElementById("navPanel"+nav).style.display="flex";
}
