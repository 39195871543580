import { Component, OnInit, Input } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
@Component({
  selector: 'app-slider-presidency',
  templateUrl: './slider-presidency.component.html',
  styleUrls: ['./slider-presidency.component.css']
})
export class SliderPresidencyComponent implements OnInit {

  constructor() { }

  @Input('people') people: any;

  index = 2;

  config: SwiperConfigInterface = {
    slidesPerView: 3,
    speed: 0,
    loop: true,
    observer: true, // enable Mutation Observer on Swiper and its elements
    observeParents: true, // enable Mutations Observer for Swiper parent elementsù
    spaceBetween: 0,
    allowTouchMove: false,
    direction: 'horizontal',
    navigation: {
      nextEl: '.presidence-button-next',
      prevEl: '.presidence-button-prev'
    }

  };

  index2 = 0;

  config2: SwiperConfigInterface = {
    slidesPerView: 1,
    initialSlide: 2,
    speed: 250,
    loop: true,
    observer: true,
    observeParents: true, // enable Mutations Observer for Swiper parent elementsù
    spaceBetween: 20,
    allowTouchMove: false,
    direction: 'horizontal',
    navigation: {
      nextEl: '.presidence-button-next',
      prevEl: '.presidence-button-prev'
    },

  };

  ngOnInit() {
  }

  indexChange() {
    console.log(this.config.navigation);
  }

}
