import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent implements OnInit {

  textInput = '';

  constructor(private router: Router) { }

  ngOnInit() {
  }

  search() {
    console.log('Cerca: ' + this.textInput);
    this.router.navigate(['/search'], {queryParams: {searchTerm: this.textInput}});
  }

}
