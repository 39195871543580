import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBottomDetector]'
})
export class BottomDetectorDirective {

  top;
  offSetHeight;
  scrollHeight;


  constructor(private eleRef: ElementRef) { }

  @HostListener('scroll') onScroll(event) {
      // Get the Value of scroll position
    this.top = this.eleRef.nativeElement.scrollTop;
        // Get the Height of the Element.This property normally  include padding and margin
    this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
        // ScrollHeight is a measurement of the height of an element's content including
    this.scrollHeight = this.eleRef.nativeElement.scrollHeight;
        // content not visible on the screen due to overflow
        //  console.log('Top:'+this.top,'Scroll height:'+this.eleRef.nativeElement.scrollHeight,'offsetHEight:'+this.offSetHeight);
        // IF the scroll position is zero which means it is at the top
    if (this.top === 0) {
            console.log('top');
        }
        // check the scroll at bottom or top
    if (this.top > this.scrollHeight - this.offSetHeight) {
          console.log('bottom');

        }
  }

}
