import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

import { PageComponent } from './pages/page/page.component';

import { WhatwedoComponent } from './pages/whatwedo/whatwedo.component';
import { EuProjectsComponent } from './pages/whatwedo/eu-projects/eu-projects.component';
import { PolicyAdvocacyComponent } from './pages/whatwedo/policy-advocacy/policy-advocacy.component';
import { CampaignsCommunicationComponent } from './pages/whatwedo/campaigns-communication/campaigns-communication.component';

import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { PeopleComponent } from './pages/who-we-are/people/people.component';
import { OrganisationComponent } from './pages/who-we-are/organisation/organisation.component';

import { PressReleasesComponent } from './pages/press-releases-container/press-releases/press-releases.component';
import { KeepInTouchComponent } from './pages/keep-in-touch/keep-in-touch.component';
import { SearchPageComponent } from './pages/search/search-page/search-page.component';
import { PressReleaseComponent } from './pages/press-releases-container/press-release/press-release.component';
import { ActivitiesComponent } from './pages/activities/activities/activities.component';
import { ActivityComponent } from './pages/activities/activity/activity.component';
import { LoginComponent } from './pages/login/login.component';

import { AuthGuardService } from './services/auth-guard.service';
import { MembersAreaComponent } from './pages/members-area/members-area.component';
import { CommunicationComponent } from './pages/whatwedo/campaigns-communication/communication/communication.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  /*
  {
    path: 'what-we-do',
    component: WhatwedoComponent
  },
  */
  {
    path: 'pages/:slug',
    component: PageComponent
  },
  {
    path: 'what-we-do/eu-projects',
    component: EuProjectsComponent
  },
  {
    path: 'what-we-do/campaigns-and-communication',
    component: CampaignsCommunicationComponent
  },
  {
    path: 'what-we-do/campaigns-and-communication/:slug',
    component: CommunicationComponent
  },
  {
    path: 'what-we-do/policy-and-advocacy',
    component: PolicyAdvocacyComponent
  },
  /*
  {
    path: 'who-we-are',
    component: WhoWeAreComponent
  },
  */
  {
    path: 'who-we-are/people',
    component: PeopleComponent,
  },
  {
    path: 'who-we-are/organisation',
    component: OrganisationComponent
  },
  {
    path: 'press-releases',
    component: PressReleasesComponent
  },
  {
    path: 'press-releases/:id',
    component: PressReleaseComponent
  },
  {
    path: 'keep-in-touch/contact',
    component: KeepInTouchComponent
  },
  {
    path: 'search',
    component: SearchPageComponent
  },
  {
    path: 'activities',
    component: ActivitiesComponent
  },
  {
    path: 'activities/:id',
    component: ActivityComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'members-area',
    component: MembersAreaComponent,
    canActivate: [AuthGuardService]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
