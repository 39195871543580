import { Injectable } from '@angular/core';
import { APIConfig } from '../shared/APIConfig.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APIHelperService {

  // private ROOT = 'https://wordpress.ceja.eu';
  // private API_BASE_URL = 'https://wordpress.ceja.eu/wp-json/wp/v2';
  private ROOT = environment.WEBSITE_ROOT;
  private API_BASE_URL = environment.API_BASE_URL;

  private API_ENDPOINTS = {
    posts: '/posts',
    pages: '/pages',
    press_release: '/press_release',
    activity_report: '/activity_report',
    position_paper: '/position_paper',
    communication: '/communication',
    eu_project: '/eu_project',
    eu_member: '/eu_member',
    search: '/search',
    calendar: '/wp-json/tribe/events/v1/events',
    country: '/country',

    ma_file_category: '/ma_file_category',
    ma_file: '/ma_file'
  };

  constructor() { }

  public generateAPILink(endpoint, config: APIConfig = null) {
    if (this.API_ENDPOINTS[endpoint] === null) {
      return null;
    }

    let apiURL = this.API_BASE_URL + this.API_ENDPOINTS[endpoint];
    if (endpoint === 'calendar') {
      apiURL = this.ROOT + this.API_ENDPOINTS[endpoint];
    }

    if (config !== null) {
      if (config.id !== undefined) {
        apiURL += '/' + config.id;
      }

      if (Object.entries(config.params).length !== 0) {
        apiURL += '?';

        // tslint:disable-next-line: forin
        for (const param in config.params) {
          apiURL += param + '=' + config.params[param] + '&';
        }
      }
    }

    return apiURL;
  }


  public generateSearchLink(term) {
    if ((term === null) || (term === '')) {
      return null;
    }

    let apiURL = this.API_BASE_URL + this.API_ENDPOINTS.search;
    apiURL += '?search=' + term;

    return apiURL;
  }
}
