import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit, OnDestroy {

  searchTerm;
  searchResult = null;
  loading = true;

  urlChangeSubscription: Subscription;

  convertionTable = {
    press_release: 'press-releases',
    // eu_project: 'eu-project',
    // eu_member: 'eu-member',
  };

  constructor(private backend: BackendService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.performSearch();

    this.urlChangeSubscription = this.router.events
    .subscribe(event => { // Change of url (doesn't mean component has been reloaded)
      if (event instanceof NavigationEnd) {
        this.performSearch();
      }
    });
  }

  ngOnDestroy() {
    this.urlChangeSubscription.unsubscribe();
  }

  private performSearch() {
    if (this.route.snapshot.queryParamMap.has('searchTerm')) {
      this.searchTerm = this.route.snapshot.queryParamMap.get('searchTerm');
      this.loading = true;
      this.backend.search(this.searchTerm)
      .then(result => {
        console.log(result);
        this.searchResult = result;
      }).catch(error => {
        console.log('Error: ', error);
      }).finally(() => {
        this.loading = false;
      });

    } else {
      this.searchTerm = null;
    }
  }

}
