import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {


  pageData: any;
  events;
  loading = true;
  error = false;
  errorMessage;


  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {


    this.pageData = {
      title: {
        rendered: 'Nome pagina'
      }
    };

    this.setLoading(true);
    const config: APIConfig = new APIConfig();

    // ---------- Events ----------
    this.backend.getCalendar(config)
      .then(events => {
        this.events = events;
        console.log('Events: ', this.events);
        this.setLoading(false);
      }).catch((error) => this.setError(error));
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}
