export class Params {
  page: number; // Default: 1
  // tslint:disable-next-line: variable-name
  per_page: number; // Default: 10
  search: string;
  after: Date;
  before: Date;
  order: 'asc' | 'desc'; // Default: desc
  orderby: 'author' | 'date' | 'id' | 'include' | 'modified' | 'parent' | 'relevance' | 'slug' | 'include_slugs' | 'title'; // Default: date
  slug: string;
  status: string; // Default: publish
  categories: string;
  tags: string;
  // tslint:disable-next-line: variable-name
  tags_exclude: string;
  // tslint:disable-next-line: variable-name
  start_date: string; // For calendar api
  // tslint:disable-next-line: variable-name
  end_date: string;

  // tslint:disable-next-line: variable-name
  ma_file_category: string; // Custom for ACF
}

export class APIConfig {
  id: number;
  params: Params;

  constructor() {
    this.params = new Params();
  }
}
