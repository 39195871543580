import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

import { MaFileCategory, Paging } from '../../shared/MaFileCategory.model';
import { MaFile } from '../../shared/MaFile.model';

@Component({
  selector: 'app-members-area',
  templateUrl: './members-area.component.html',
  styleUrls: ['./members-area.component.css']
})
export class MembersAreaComponent implements OnInit {


  constructor(private backend: BackendService,
              private sync: SyncService,
              private auth: AuthService,
              private router: Router) { }

  pageData: any;
  loading = true;
  error = false;
  errorMessage;

  categoryIDSelected = null;

  // New structure
  MFC_MAP: Map<number, MaFileCategory> = new Map();
  MFC_PARENTS: MaFileCategory[] = [];
  MF_SHOWN: MaFile[] = [];

  INITIAL_CONTENT_PER_PAGE = 5;
  CONTENT_PER_LOAD_MORE = this.INITIAL_CONTENT_PER_PAGE;

  name;

  classActive = 1;

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 373;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
      .then(pageData => {
        this.pageData = pageData;
        // console.log('Page data: ', this.pageData);
      }).catch((error) => this.setError(error));

    this.name = this.auth.getName();
    // console.log('Name: ', this.name);

    // ---------- Ma File Category ----------
    this.backend.getMaFileCategory()
    .then(category => {
      category.forEach(element => {
        const mfc: MaFileCategory = MaFileCategory.fromDBData(element);
        this.MFC_MAP.set(element.id, mfc);
      });
      // console.log('MaFileCategory:', this.maFileCategory);
      // console.log('MFG_MAP after getMaFileCategory: ', this.MFC_MAP);
    }).then(() => {

      const promises = [];
      // tslint:disable-next-line: forin
      this.MFC_MAP.forEach(entry => {
        const categoryID = entry.id;

        const configFile = new APIConfig();
        configFile.params.per_page = this.INITIAL_CONTENT_PER_PAGE;
        configFile.params.ma_file_category = categoryID.toString();
        const prom = this.backend.getMaFile(configFile)
        .then(mafiles => {
          this.MFC_MAP.get(categoryID).addFiles(mafiles);
        });
        promises.push(prom);
      });

      Promise.all(promises)
      .then(() => {
        // console.log("maFileByCategory: ", this.maFileByCategory);
        // console.log('MFG_MAP after getMaFileCategory: ', this.MFC_MAP);
      }).then(() => { // Complete child lists
        this.MFC_MAP.forEach(entry => {
          if (entry.parent !== 0) {
            this.MFC_MAP.get(entry.parent).addChildId(entry.id);
          } else {
            this.MFC_PARENTS.push(entry);
          }
        });

        this.setLoading(false);
        console.log('MFG_MAP after completing list of childIds: ', this.MFC_MAP);
        // console.log('MFC_PARENTS: ', this.MFC_PARENTS);
      });
    });

    this.initMenuEvent();
  }


  filter(categoryID) {
    console.log('Selected categoryID: ' + categoryID);
    this.categoryIDSelected = categoryID;

    this.MF_SHOWN = this.MFC_MAP.get(parseInt(categoryID, 10)).files;
    console.log('MF_SHOWN: ', this.MF_SHOWN);
  }

  loadMore() {

    const categorySelected: MaFileCategory = this.MFC_MAP.get(parseInt(this.categoryIDSelected, 10));
    const pagingSetting: Paging = categorySelected.paging;
    console.log('pagingSetting selected: ', pagingSetting);

    const configFile = new APIConfig();
    configFile.params.per_page = this.CONTENT_PER_LOAD_MORE;
    configFile.params.page = pagingSetting.pageNumber;
    configFile.params.ma_file_category = this.categoryIDSelected;


    if (pagingSetting.thereIsMore) {

      this.backend.getMaFile(configFile)
      .then(mafiles => {
        categorySelected.addFiles(mafiles);
        // console.log('Mafiles for id: ' + this.categoryIDSelected, mafiles);
        this.filter(this.categoryIDSelected); // Update view since object changed
        pagingSetting.incrementPageNumber();

        if (mafiles.length < this.CONTENT_PER_LOAD_MORE) { // No more pages
          pagingSetting.thereIsMore = false;
        }

        console.log('MFG_MAP after loading morte for id: ' + categorySelected.id, this.MFC_MAP);

      })
      .catch(e => {
        pagingSetting.thereIsMore = false; // Not loaded because no more pages
      });

    }
  }

  isThereMoreToLoad() {

    if (this.MF_SHOWN.length < this.CONTENT_PER_LOAD_MORE) {
      return false;
    }

    let pageSetting: Paging = this.MFC_MAP.get(parseInt(this.categoryIDSelected, 10)).paging;

    return pageSetting.thereIsMore;
  }

  setClassActive(n) {
    this.classActive = n;
  }

  private logout() {
    this.auth.logout();
    this.router.navigateByUrl('/home');
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

  private initMenuEvent(){
    document.querySelector('#maMenuController').addEventListener('click', function() {
      if(document.documentElement.clientWidth>640)return false;
      var controller=<HTMLElement>document.querySelector('#maMenuController');
      //console.dir(controller);
      if(controller.style.left!='-100%'){
        controller.style.left='-100%';
      }else{
          controller.style.left='0';
      }


    });

  }
}
