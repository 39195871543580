import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReplaceSource } from 'webpack-sources';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private AUTH_URL = 'https://wordpress.ceja.eu/wp-json/jwt-auth/v1/token';
  private loginEvent: Subject<any> = new Subject();

  constructor(private http: HttpClient,
              private jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false

    return !this.jwtHelper.isTokenExpired(token);
  }

  // Performs login and returns true or false
  // It stores inside localStorage the token
  public login(username, password): Promise<boolean> {

    return new Promise<any>((resolve, reject) => {

      this.http.post(this.AUTH_URL, {username, password})
      .subscribe((response: any) => {
        // Check respose to see if auth was successful
        // If positive => set localStorage

        console.log('Response: ', response);

        localStorage.setItem('token', response.token.toString());
        localStorage.setItem('name', response.user_display_name.toString());
        this.loginEvent.next(true);
        resolve(true);

      }, error => {
        console.log(error.error);
        reject(error.error);
      });

    });
  }

  public logout() {
    localStorage.clear();
    this.loginEvent.next(false);
  }

  public getName() {
    return localStorage.getItem('name');
  }

  public isLogged() {
    return this.loginEvent;
  }

}
