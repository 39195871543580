import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatwedo',
  templateUrl: './whatwedo.component.html',
  styleUrls: ['./whatwedo.component.css']
})
export class WhatwedoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
