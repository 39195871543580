import { MaFile } from './MaFile.model';

export class Paging {
  pageNumber: number;
  thereIsMore: boolean;

  constructor() {
    this.pageNumber = 2;
    this.thereIsMore = true;
  }

  public incrementPageNumber() {
    this.pageNumber++;
  }
}

export class MaFileCategory {
  id: number;
  count: number;
  name: string;
  slug: string;
  taxonomy: string;
  parent: number;

  childIds: number[];
  files: MaFile[];

  paging: Paging;


  constructor(id, count, name, slug, taxonomy, parent) {
    this.id = id;
    this.count = count;
    this.name = name;
    this.slug = slug;
    this.taxonomy = taxonomy;
    this.parent = parent;

    this.childIds = [];
    this.files = [];
    this.paging = new Paging();
  }

  static fromDBData(obj: any) {
    return new MaFileCategory(obj.id, obj.count, obj.name, obj.slug, obj.taxonomy, obj.parent);
  }

  public canLoadMore() {
    return this.paging.thereIsMore;
  }

  public hasChilds() {
    return (this.childIds.length !== 0);
  }

  public addFiles(files: any) {
    files.forEach(file => {
      this.files.push( MaFile.fromDBData(file) );
    });
  }

  public addChildId(id) {
    this.childIds.push(id);
  }
}
