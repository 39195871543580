import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-slider-cc',
  templateUrl: './slider-cc.component.html',
  styleUrls: ['./slider-cc.component.css']
})
export class SliderCcComponent implements OnInit {

  index = 0;
  index2 = 0;
  // tslint:disable-next-line: no-input-rename
  @Input('content') content: any;

  config: SwiperConfigInterface = {
    slidesPerView: 1,
    speed: 500,
    observer: true, // enable Mutation Observer on Swiper and its elements
    observeParents: true, // enable Mutations Observer for Swiper parent elementsù
    direction: 'horizontal',
    navigation: {
      nextEl: '.swiper-btn.next',
      prevEl: '.swiper-btn.prev'
    }
  };

  config2: SwiperConfigInterface = {
    slidesPerView: 1,
    speed: 500,
    observer: true, // enable Mutation Observer on Swiper and its elements
    observeParents: true, // enable Mutations Observer for Swiper parent elementsù
    direction: 'horizontal',
    navigation: {
      nextEl: '.swiper-btn.next',
      prevEl: '.swiper-btn.prev'
    }
  };

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log('Content: ', this.content);
  }

  indexChange() {
    console.log('Index: ' + this.index);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  replace(videoUrl: string){
    let vimeoUrlcheck = videoUrl.includes("vimeo");
    let youtubeUrlcheck = videoUrl.includes("youtube");

    if(youtubeUrlcheck){
      let ytbID = videoUrl.replace('https://www.youtube.com/watch?v=', '');
      let ytbIframeUrl = 'https://www.youtube.com/embed/' + ytbID;
      return this.sanitizer.bypassSecurityTrustResourceUrl(ytbIframeUrl);
    }if(vimeoUrlcheck){
      let vimeoID = videoUrl.replace('https://vimeo.com/', '');
      let vimeoIframeUrl = 'https://player.vimeo.com/video/' + vimeoID + '?title=0&amp;byline=0&amp;portrait=0';
      return this.sanitizer.bypassSecurityTrustResourceUrl(vimeoIframeUrl);
    }
  }

}
