import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { environment } from 'src/environments/environment';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-members-map',
  templateUrl: './members-map.component.html',
  styleUrls: ['./members-map.component.css']
})
export class MembersMapComponent implements OnInit {

  loading = true;
  error = false;
  errorMessage;
  euMembers = [];
  countries;

  bindingSet = [];
  selected = null;
  selecteds = null;

  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {

    this.setLoading(true);
    this.loadSVGasXML()
    .then(() => {

      const config = new APIConfig();
      config.params.per_page = 100;

      this.backend.getEuMembers(config)
      .then(euMembers => {

        // Load countries
        const config2 = new APIConfig();
        config2.params.per_page = 100;

        this.backend.getCountries(config2)
        .then(countries => {
          this.countries = countries;

          this.convertEuMembers(countries, euMembers);

          console.log('Eu Members: ', this.euMembers);
          this.setLoading(false);

          let element: HTMLElement = document.getElementById('membersMap');
          if (element.children[0] !== undefined) {
            // console.log('Element: ', element.children[0].children);
            let arr = [];
            var ii = 0;

            this.euMembers.forEach(euMember => {
              //console.log(euMember.country.slug + euMember.title);

              if (this.bindingSet.indexOf(euMember.country.slug) === -1) {
                try {

                  let m = document.getElementById(euMember.country.slug)
                  .addEventListener('click', () => {
                    this.selected = euMember;
                    this.selecteds = this.euMembers.filter((member, index, array) => member.country.slug == euMember.country.slug);

                    console.log(arr);

                    var els = document.querySelectorAll('.active');
                    for (var i = 0; i < els.length; i++) {
                      els[i].classList.remove('active');
                    }
                    document.getElementById(euMember.country.slug).classList.add('active');

                  });

                } catch (error) {
                  console.log('Error in binding with ' + euMember.country.slug);
                }
                this.bindingSet.push(euMember.country.slug);
              }
            });
          }

        });

      });

    });


  }

  private convertEuMembers(countries, euMembers) {
    const middleConversion = {};
    countries.forEach(element => {
      middleConversion[element.id] = {
        slug: element.slug,
        name: element.name
      };
    });

    this.euMembers = [];
    euMembers.forEach(element => {
      this.euMembers.push({
        title: element.title.rendered,
        acf: element.acf,
        country: {
          slug: middleConversion[element.country[0]].slug,
          name: middleConversion[element.country[0]].name
        }
      });
    });
  }


  private loadingComplete(newData) {
    this.setLoading(false);
    if (environment.VERBOSE) {
      console.log('New data:', newData);
    }
  }


  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

  // SVG external to inline
  loadSVGasXML() {
    return new Promise(function(resolve, reject){
      const SVGFile = '/assets/img/membersMap.svg';
      const loadXML = new XMLHttpRequest();
      function handler() {
          if (loadXML.readyState === 4 && loadXML.status === 200) {
            document.getElementById('membersMap').innerHTML = loadXML.responseText;
            console.log('XMLHttpRequest done');
            resolve();
          }
      }
      if (loadXML !== null) {
          loadXML.open('GET', SVGFile, true);
          loadXML.onreadystatechange = handler;
          loadXML.send();
      }
    });

  }

}
