import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { ActivatedRoute } from '@angular/router';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-press-release',
  templateUrl: './press-release.component.html',
  styleUrls: ['./press-release.component.css']
})
export class PressReleaseComponent implements OnInit {


  id = null;
  pressReleases;
  loading = true;
  error = false;
  errorMessage;

  constructor(private route: ActivatedRoute,
              private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {

    this.setLoading(true);

    const config: APIConfig = new APIConfig();

    if (this.route.snapshot.paramMap.has('id')) {
      this.id = this.route.snapshot.paramMap.get('id');
      config.id = this.id;
    } else {
      this.id = null;
    }

    this.backend.getPressReleases(config)
    .then(data => {
      this.pressReleases = data;
      console.log('Data: ', data);
      this.setLoading(false);
    }).catch(error => {
      console.log('Error', error);
      this.error = true;
      this.errorMessage = error;
    });
  }


  private setLoading(value: boolean, level = 1, obj: any = null) {
    this.sync.updateLoading(value, level, obj);
    this.loading = value;
  }

}
