import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  public loading: Subject<any>;
  public logged: Subject<any>;

  constructor() {
    this.loading = new Subject();
    this.logged = new Subject();
  }

  public updateLoading(loading: boolean, level: number = 1, additionalData: any = null) {
    const data = {
      loading,
      level,
      additionalData
    };

    this.loading.next(data);
  }

  public login(l: boolean) {
    this.logged.next(l);
  }
}
