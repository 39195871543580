import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { SyncService } from 'src/app/services/sync.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-keep-in-touch',
  templateUrl: './keep-in-touch.component.html',
  styleUrls: ['./keep-in-touch.component.css']
})
export class KeepInTouchComponent implements OnInit {
  pageData: any;
  loading = true;
  error = false;
  errorMessage;


  constructor(private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 30;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
      .then(pageData => {
        this.pageData = pageData;
        console.log('Page data: ', this.pageData);
        this.setLoading(false);
      }).catch((error) => this.setError(error));

    const navBtnList = document.getElementsByClassName('navBtn');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < navBtnList.length; i++) {
        navBtnList[i].addEventListener('click', function() {
          // tslint:disable-next-line: no-shadowed-variable
          const navBtnList = document.getElementsByClassName('navBtn');
          // tslint:disable-next-line: no-shadowed-variable
          for (let i = 0; i < navBtnList.length; i++) {
            (navBtnList[i] as HTMLElement).className = ( navBtnList[i] as HTMLElement).className.replace(' active', '');
          }
          this.className += ' active';
          ceyaIsSocialSelectNav(this.getAttribute('social'));
        });
      }
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.setLoading(false);
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean) {
    this.sync.updateLoading(value);
    this.loading = value;
  }

}


function ceyaIsSocialSelectNav(nav) {
  const panelList = document.getElementsByClassName('cardPanel');
  for (let i = 0; i < panelList.length; i++) {
      ( panelList[i] as HTMLElement).style.display = 'none';
  }
  document.getElementById('navPanel' + nav).style.display = 'flex';
}
