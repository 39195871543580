import { Component, OnInit } from '@angular/core';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./sass/footer.component.scss']
})
export class FooterComponent implements OnInit {

  loading = true;
  currentYear = new Date().getFullYear();

  constructor(private sync: SyncService) { }

  ngOnInit() {
    this.sync.loading
    .subscribe((data) => {
      //console.log('Data: ', data);
      if (data.level === 1) {
        this.loading = data.loading;
      }
    });
  }
}
