import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../../services/backend.service';
import { APIConfig } from 'src/app/shared/APIConfig.model';
import { environment } from 'src/environments/environment';
import { SyncService } from 'src/app/services/sync.service';

@Component({
  selector: 'app-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.css']
})
export class PressReleasesComponent implements OnInit {

  pageData: any;
  id = null;
  pressReleases = [];
  loading = true;
  error = false;
  errorMessage;
  currentPage = 1;

  constructor(private route: ActivatedRoute,
              private backend: BackendService,
              private sync: SyncService) { }

  ngOnInit() {
    this.setLoading(true);
    const config: APIConfig = new APIConfig();
    config.id = 28;

    // ---------- Page configuration ----------
    this.backend.getPages(config)
    .then(homepage => {
      this.pageData = homepage;
      console.log('Page data: ', this.pageData);
      this.setLoading(false);
    }).catch((error) => this.setError(error));


    this.loadData()
      .then((newData) => this.loadingComplete(newData))
      .catch((error) => this.setError(error));
  }

  private loadData(page = 1) {
    this.setLoading(true, 2, {name: 'newdata'});
    return new Promise((resolve, reject) => {
      const config: APIConfig = new APIConfig();
      config.params.per_page = 10;
      config.params.page = page;

      this.backend.getPressReleases(config)
      .then(data => {
        this.pressReleases = [...this.pressReleases, ...data];
        this.currentPage = page;
        this.setLoading(false, 2, {name: 'newdata'});
        resolve(this.pressReleases);
      }).catch(error => {
        reject(error);
      });
    });
  }


  private loadMore() {
    if (!this.loading) {
      this.loadData(this.currentPage + 1)
      .then((newData) => this.loadingComplete(newData))
      .catch((error) => this.setError(error));
    }
  }

  private loadingComplete(newData) {
    this.loading = false;
    if (environment.VERBOSE) {
      console.log('New data:', newData);
    }
  }

  private setError(error) {
    if (environment.VERBOSE) {
      console.log('Error:', error);
    }
    this.loading = false;
    this.error = true;
    this.errorMessage = error;
  }

  private setLoading(value: boolean, level = 1, obj: any = null) {
    this.sync.updateLoading(value, level, obj);
    this.loading = value;
  }

}
