import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-opening-page',
  templateUrl: './section-opening-page.component.html',
  styleUrls: ['./section-opening-page.component.css']
})
export class SectionOpeningPageComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('loading') loading: any;
  // tslint:disable-next-line: no-input-rename
  @Input('pageData') pageData: any;
  // tslint:disable-next-line: no-input-rename
  @Input('hierarchy') hierarchy: any;

  constructor() { }

  ngOnInit() {
  }

}
